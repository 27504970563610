<template>
  <div>
    <section>
      <div class="box p-2 mt-2">
        <div
          class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row"
          role="tablist"
        >
          <router-link
            id="tutela-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-proceso' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-proceso'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <TabletIcon class="w-4 h-4 mr-2"/>
            Información del Proceso
          </router-link>
          <router-link
            id="fallos-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos',
              'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos.agregar-demandante',
              'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos.agregar-demandado'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <ShoppingBagIcon class="w-4 h-4 mr-2"/>
            Sujetos Proceso
          </router-link>
          <router-link
            id="incidentes-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones',
              'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones.crear'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <ShoppingBagIcon class="w-4 h-4 mr-2"/>
            Actuaciones
          </router-link>
        </div>
      </div>
    </section>
    <div class="mt-4">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'VerMain',
  setup () {
    const route = useRoute()

    const getActive = (routeNames = []) => {
      if (routeNames.includes(route.name)) {
        return 'active'
      }
      return ''
    }

    return {
      getActive
    }
  }
}
</script>

<style scoped>

</style>
